import { zeroAddress } from 'viem'
import {
  cronos,
  Chain,
  cronosTestnet,
  base
} from 'wagmi/chains'
import usdcContracts from './usdcContracts'

export enum ChainId {
  CRONOS = 25,
  CRONOS_TESTNET = 338,
}

export type ReservoirChain = Chain & {
  lightIconUrl: string
  darkIconUrl: string
  reservoirBaseUrl: string
  proxyApi?: string
  routePrefix: string
  apiKey?: string
  coingeckoId?: string
  collectionSetId?: string
  community?: string
  wssUrl?: string
  listingCurrencies?: any[]
  oracleBidsEnabled?: boolean
  checkPollingInterval?: number
}

const nativeCurrencyBase = {
  contract: zeroAddress,
  decimals: 18,
  symbol: 'CRO',
  coinGeckoId: 'crypto-com-chain',
}

const usdcCurrencyBase = {
  contract: '',
  symbol: 'USDC',
  decimals: 6,
  coinGeckoId: 'usd-coin',
}

export const DefaultChain: ReservoirChain = {
  ...cronos,
  // Any url to display the logo of the chain in light mode
  lightIconUrl: '/icons/eth-icon-dark.svg',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: '/icons/eth-icon-light.svg',
  // The base url of the reservoir api, this is used in the app when
  // directly interacting with the reservoir indexer servers (in the api proxy for example)
  // or when prefetching server side rendered data
  reservoirBaseUrl: process.env.NEXT_PUBLIC_BASE_API as string,
  // Used on the client side portions of the marketplace that need an api key added
  // Prevents the api key from being leaked in the clientside requests
  // If you'd like to disable proxying you can just change the proxyApi to the reservoirBaseUrl
  // Doing so will omit the api key unless further changes are made
  proxyApi: '/api/reservoir/cronos',
  // A prefix used in the asset specific routes on the app (tokens/collections)
  routePrefix: 'cronos',
  // Coingecko id, used to convert the chain's native prices to usd. Can be found here:
  // https://www.coingecko.com/en/api/documentation#operations-coins-get_coins_list
  coingeckoId: 'crypto-com-chain',
  wssUrl: 'wss://cronos-evm-rpc.publicnode.com',
  rpcUrls:{
    default: {
      http: ['https://cronos-evm-rpc.publicnode.com'], // Default HTTP RPC
      webSocket: ['wss://cronos-evm-rpc.publicnode.com'], // Optional WebSocket RPC
  }},
  listingCurrencies: [
    nativeCurrencyBase,
    {
      ...usdcCurrencyBase,
      contract: usdcContracts[cronos.id],
    },
  ],
  oracleBidsEnabled: true,
  checkPollingInterval: 0,
}

export default [
  DefaultChain,
  {
    ...cronosTestnet,
    lightIconUrl: '/icons/cronos.svg',
    darkIconUrl: '/icons/cronos.svg',
    reservoirBaseUrl: '/',
    proxyApi: '/api/cronosTestnet',
    routePrefix: 'cronosTesnet',
    coingeckoId: 'crypto-com-chain',
    wssUrl: 'wss://ws-bsc.reservoir.tools',
    listingCurrencies: [
      { ...nativeCurrencyBase, coinGeckoId: 'crypto-com-chain' },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[cronosTestnet.id],
      },
    ],
    checkPollingInterval: 0,
  },
] as ReservoirChain[]

export type ROBINChain = {
  id: number
  lzId: number
  name: string
  routePrefix?: string
  address: `0x${string}`
  iconUrl: string
}

export const DefaultROBINChain: ROBINChain = {
  id: cronos.id,
  lzId: 109,
  name: cronos.name,
  routePrefix: 'cronos',
  address: '0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
  iconUrl: '/icons/cronos.svg',
}

export const ROBIN_CHAINS: ROBINChain[] = [
  DefaultROBINChain,
  {
    id: base.id,
    lzId: 158,
    name: base.name,
    routePrefix: 'base',
    address: '0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
    iconUrl: '/icons/base.svg',
  }
]